import React from 'react';
import './index.scss';
import marker from './../../../assets/icons/marker.png'
import {NavLink, Link} from 'react-router-dom'
import {connect} from 'react-redux'

function Navigation(props) {
  return (
  	<div id="navigation" className={`row color-bg-${props.color}`}>
  		<div className="row-inner">
	  		<Link to='/'><img className="icon-marker" src={marker}/></Link>
		    <ul>
		    	{props.data.map((ele, i) => (
			    		<li key={i}>
			    			<NavLink to={ele.slug}><span>{ele.title}</span></NavLink>
						</li>
		    		))}
		    	<li key="10000">
	    			<a href="#footer"><span>Kontakt</span></a>
				</li>
		    </ul>
	   	</div>
   	</div>
  );
}

export default connect((state) => ({
	color: state.App.color
}))(Navigation);
