import React from 'react';
import './index.scss';

function PageContent(props) {
  return (
    <div className="row pagecontent">
		<div className="row-inner">
			{props.children}
		</div>
	</div>
  );
}

export default PageContent;
