import React, {useEffect, useState} from 'react';
import './index.scss';

import {getApiBase, getURLBase, formatString} from './../../helpers/helpers'
import { Header, Footer, Document, Packer, Paragraph, TextRun, Media, HorizontalPositionRelativeFrom, HorizontalPositionAlign, VerticalPositionRelativeFrom, VerticalPositionAlign } from 'docx'
import {saveAs} from 'file-saver'
import {connect} from 'react-redux'

import {useParams} from 'react-router-dom'

import QuestionSingle from './../QuestionSingle'
import PageContent from './../PageContent'
import PageHeader from './../PageHeader'
import PageFooter from './../PageFooter'
import Loading from './../Loading'

import markerImg from './../../../assets/icons/marker.png'

function Questionnaire(props) {
	const [apiData, setApiData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	let {id} = useParams();

	useEffect(() => {
		setIsLoading(true)

		const fetchData = async () => {
			const response = await fetch(getApiBase()+'/wp-json/wp/v2/skemaer?slug='+id);
			const jsonResponse = await response.json();
			setApiData(jsonResponse[0])
			setIsLoading(false)
			props.dispatch({
				type: 'SET_COLOR',
				value: jsonResponse[0].acf.farve
			})
		}
		fetchData();
	}, [id])

	const createDocument = async () => {
		const doc = new Document();
		let arrayOfQuestions = []

		// headline
			let documentHeadline = new Paragraph({
			            children: [
			                new TextRun({
			                	text: apiData.title.rendered,
			                	bold: true,
			                	size: 60,
			                	font: {name: 'Arial'}
			                }),
			            ],
			        })

			arrayOfQuestions.push(documentHeadline)

		// themes + sections + questions
			let breakPara = new TextRun({text: ''}) 
			breakPara.break();

			for (var i = 0; i < apiData.acf.section.length; i++) {
				
				// theme
				if (apiData.acf.section[i].type == 'theme') {
					let theme_title = new Paragraph({
			            children: [
			            	breakPara, 
			            	breakPara, 
			                new TextRun({
			                	text: apiData.acf.section[i].titel,
			                	bold: true,
			                	size: 48,
			                	font: {name: 'Arial'},
			                }),
			            	breakPara
			            ]
			        })
					arrayOfQuestions.push(theme_title);

					let theme_description = new Paragraph({
			            children: [
			                new TextRun({
			                	text: apiData.acf.section[i].theme_description,
			                	size: 24,
			                	font: {name: 'Arial'}
			                }),
			            ],
			        })
					arrayOfQuestions.push(theme_description);

				} else {
				// sections and questions
					var questionsInSection = false;
					for (var i2 = 0; i2 < apiData.acf.section[i].question.length; i2++) {
						if (apiData.acf.section[i].question[i2].selected || apiData.acf.section[i].question[i2].prepicked) {
							var questionToAdd = []
							if (!questionsInSection) {
								questionsInSection = true;
								let sectionTitle = new TextRun({
				                	text: apiData.acf.section[i].titel,
				                	bold: true,
				                	size: 36,
				                	font: {name: 'Arial'}
				                })
				                sectionTitle.break();
				                sectionTitle.break();							
				                questionToAdd.push(sectionTitle)
							}

							let questTitle = new TextRun({
			                	text: apiData.acf.section[i].question[i2].canedit ? apiData.acf.section[i].question[i2].text_canedit : formatString(apiData.acf.section[i].question[i2].text),
			                	size: 24,
			                	font: {name: 'Arial'}
			                })
			                questTitle.break();
			                questTitle.break();
			                questionToAdd.push(questTitle)
	                		questionToAdd.push(breakPara);

			                // medarbejderens svar
				                var lineTitle = new TextRun({
				                	text: apiData.acf.respondent_1,
				                	size: 20,
				                	font: {name: 'Arial'},
					               	italics: true
				                })
				                lineTitle.break();
					            questionToAdd.push(lineTitle)

				                for (var p = 0; p < 3; p++) {
					                var line = new TextRun({
					                	text: '____________________________________________________________',
					                	size: 24,
						               	font: {name: 'Arial'}
					                })
					                line.break().break();
					                questionToAdd.push(line)
				                }

			                // lederens reflektioner
		                		questionToAdd.push(breakPara);
		                		questionToAdd.push(breakPara);
				                var lineTitle = new TextRun({
				                	text: apiData.acf.respondent_2,
				                	size: 20,
				                	font: {name: 'Arial'},
					               	italics: true
				                })
				                lineTitle.break();
					            questionToAdd.push(lineTitle)

				                for (var p = 0; p < 2; p++) {
					                var line = new TextRun({
					                	text: '____________________________________________________________',
					                	size: 24,
						               	font: {name: 'Arial'}
					                })
					                line.break().break();
					                questionToAdd.push(line)
				                }

							let newQuestion = new Paragraph({
						            children: questionToAdd
						        })
							arrayOfQuestions.push(newQuestion)
						}
					}	
				}
				
			}

		// images for header and footer
			// const markBlob = await fetch(getURLBase()+'/mark.png').then(r => r.blob());
			// const logoBlob = await fetch(getURLBase()+'/logo.png').then(r => r.blob());
			const markBlob = await fetch('mark.png').then(r => r.blob());
			const logoBlob = await fetch('logo.png').then(r => r.blob());
			const markImgDocX = Media.addImage(doc, markBlob, 30, 30, {
				floating: {
					horizontalPosition: {
						// relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
						offset: 6914400
					},
					verticalPosition: {
			            // relative: VerticalPositionRelativeFrom.TOP_MARGIN,
			            offset: 300000
			        }
				}
			})
			const logoImgDocX = Media.addImage(doc, logoBlob, 98, 40, {
				floating: {
					horizontalPosition: {
						// relative: HorizontalPositionRelativeFrom.LEFT_MARGIN,
						offset: 350000
					},
					verticalPosition: {
			            relative: VerticalPositionRelativeFrom.BOTTOM_MARGIN,
			            offset: 200000
			        }
		        }
			})
			// const logoImg = Media.addImage(doc, markerImg)

		// add questions to output
			doc.addSection({
				headers: {
					default: new Header({
						children: [new Paragraph({children: [markImgDocX]})]
					})
				},
				footers: {
					default: new Footer({
						children: [new Paragraph({children: [logoImgDocX]})]
					})
				},
			    children: arrayOfQuestions
			});

		// add legalwriting to end of document
		
			let legalDocSections = []
			let legalDocHeadline = new Paragraph({
				children: [
						new TextRun({
		                	text: apiData.acf.doc_title,
		                	bold: true,
		                	size: 60,
		                	font: {name: 'Arial'}
		                }),
		                breakPara,
		                breakPara,
						new TextRun({
		                	text: apiData.acf.doc_description,
		                	size: 24,
		                	font: {name: 'Arial'}
	                	}),
		                breakPara,
		                breakPara
					]
			})

			legalDocSections.push(legalDocHeadline);

			// legal sections
				for (var i = 0; i < apiData.acf.doc_section.length; i++) {
					var questionsInSection = false;
					for (var i2 = 0; i2 < apiData.acf.doc_section[i].question.length; i2++) {
						var questionToAdd = []
						if (!questionsInSection) {
							questionsInSection = true;
							let sectionTitle = new TextRun({
			                	text: apiData.acf.doc_section[i].titel,
			                	bold: true,
			                	size: 36,
			                	font: {name: 'Arial'}
			                })
			                sectionTitle.break();
			                sectionTitle.break();							
			                questionToAdd.push(sectionTitle)
						}

						let questTitle = new TextRun({
		                	text: apiData.acf.doc_section[i].question[i2].text,
		                	size: 24,
		                	font: {name: 'Arial'}
		                })
		                questTitle.break();
		                questTitle.break();
		                questionToAdd.push(questTitle)

		                for (var p = 0; p < apiData.acf.doc_section[i].question[i2].antal_tomme_linjer; p++) {
			                var line = new TextRun({
			                	text: '____________________________________________________________',
			                	size: 24,
				               	font: {name: 'Arial'}
			                })
			                line.break().break();
			                questionToAdd.push(line)
		                }

						let newQuestion = new Paragraph({
					            children: questionToAdd
					        })
						legalDocSections.push(newQuestion)
					}	
				}

			doc.addSection({
				children: legalDocSections
			})

		// export
			Packer.toBlob(doc).then(blob => {
	            saveAs(blob, apiData.title.rendered+"-skema.docx");
	        });
	}

	const handleFormSubmit = (e) => {
		e.preventDefault();
		createDocument();
	}

	const handleCheckboxChange = (sectionIndex, questIndex) => {
		setApiData({
			...apiData,
			acf: {
				...apiData.acf,
				section: apiData.acf.section.map((item, i) => 
					sectionIndex === i ? {
						...apiData.acf.section[i],
						question: apiData.acf.section[i].question.map((qItem, qI) => 
							questIndex === qI ? {
								...apiData.acf.section[i].question[qI],
								selected: !apiData.acf.section[i].question[qI].selected 
							} : qItem)
					} : item)
			}
		})
	}

	const handleTextinputChange = (sectionIndex, questIndex, textValue) => {
		setApiData({
			...apiData,
			acf: {
				...apiData.acf,
				section: apiData.acf.section.map((item, i) => 
					sectionIndex === i ? {
						...apiData.acf.section[i],
						question: apiData.acf.section[i].question.map((qItem, qI) => 
							questIndex === qI ? {
								...apiData.acf.section[i].question[qI],
								text_canedit: textValue
							} : qItem)
					} : item)
			}
		})
	}

  return (
    isLoading ? <Loading/> : 
  	<div>
	    <div className={`questionnaire`}>
	    		<div>
	    			<PageHeader color={apiData.acf.farve} title={apiData.acf.title} description={apiData.acf.description}/>
		    		<PageContent>
						<form onSubmit={handleFormSubmit}>
				    		{apiData.acf.section.map((ele, sectionIndex) => {
					    		return(ele.type == 'theme' ? 
				    				<div className="theme-intro" key={sectionIndex}>
				    					<h2>{ele.titel}</h2>
				    					<p>{ele.theme_description}</p>
				    				</div>
				    				:
					    			<div className="section" key={sectionIndex}>
					    				<h3 className="section-title">{ele.titel}</h3>
					    				{ele.min_questions > 0 ? 
					    					<p className="min_quest">vælg min. {ele.min_questions} {ele.min_questions == 1 ? 'område' : 'områder'} i denne kategori</p>
					    					: null
					    				}
					    				<ul>
					    				{ele.question.map((quest, questIndex) => (
					    					<QuestionSingle key={questIndex} sectionIndex={sectionIndex} questIndex={questIndex} questData={quest} onChange={handleCheckboxChange} onChangeText={handleTextinputChange}/>
					    				))}
					    				</ul>
					    			</div>
					    		)})}
								<input type="submit" value="Download dit personlige skema i word" className={`color-bg-${apiData.acf.farve}`}/>
						</form>
					</PageContent>
				</div>
	    </div>
	    <PageFooter color={apiData.acf.farve}/>
    </div>
		    
  );
}

export default connect()(Questionnaire);
