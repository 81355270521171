const initialState = {
	color: 'white',
	appData: null
}

const App = (state = initialState, action) => {
	switch(action.type) {

		case 'SET_COLOR':
			return {
				...state,
				color: action.value,
			}
		break;

		case 'SET_APP_DATA':
			return {
				...state,
				appData: action.data,
			}
		break;

		default:
			return state
	}
}

export default App