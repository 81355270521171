import React from 'react';
import './index.scss';

function PageHeader(props) {
  return (
	<div className={`row header color-bg-${props.color}`}>
		<div className="row-inner">
			<h1 className="title">{props.title}</h1>
    		<p className="description" dangerouslySetInnerHTML={{__html:props.description}}/>
		</div>
	</div>
  );
}

export default PageHeader;
