import React from 'react';
import './index.scss';

function QuestionSingle(props) {
  return (
    <li className="question" key={props.questIndex}>
		<label>
			<input type="checkbox" className="q_checkbox" name={`question-${props.sectionIndex}-${props.questIndex}`} checked={props.questData.prepicked ? props.questData.prepicked : props.questData.selected} onChange={() => props.onChange(props.sectionIndex, props.questIndex)}/>
			<div className="qtitle">
				{!props.questData.canedit ? 
					<div className="inner" dangerouslySetInnerHTML={{__html: props.questData.text}}/>
					: <input type="text" value={props.questData.text_canedit} onChange={(e) => props.onChangeText(props.sectionIndex, props.questIndex, e.target.value)}/>
				}
				{props.questData.prepicked ? <span className="prepicked"><br/>(kan ikke fravælges)</span> : ''}
			</div>
		</label>
	</li>
  );
}

export default QuestionSingle;
