import React from 'react';
import './index.scss';

function Loading() {
  return (
    <div className="loading"/>
  );
}

export default Loading;
