import React, {useEffect, useState} from 'react';
import './index.scss';

import {connect} from 'react-redux'

import marker from './../../../assets/icons/marker.png'

import {getApiBase, getURLBase, formatString} from './../../helpers/helpers'

import Loading from './../Loading'
import PageHeader from './../PageHeader'
import PageContent from './../PageContent'
import PageFooter from './../PageFooter'

function Frontpage(props) {
	const [apiData, setApiData] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true)

		const fetchData = async () => {
			const response = await fetch(getApiBase()+'/wp-json/wp/v2/acf/options');
			const jsonResponse = await response.json();
			setApiData(jsonResponse)
			setIsLoading(false)
			props.dispatch({
				type: 'SET_COLOR',
				value: 'sand'
			})
		}
		fetchData();
	}, [])

  return (
    isLoading ? <Loading/> : 
    <div className="frontpage">
	    <PageHeader color={'sand'} title={apiData.frontpage_title} description={apiData.frontpage_description}/>
	    <PageContent>
	    	{apiData.frontpage_section.map((ele, id) => (
		    	<div key={id} className="theme-intro">
	    			<h2>{ele.title}</h2>
	    			{ele.subsection.map((subEle, subId) => (
			    		<div key={subId} className="para">
			    			<img className="icon" src={subEle.icon}/>
			    			<div className="text" dangerouslySetInnerHTML={{__html: subEle.text}}/>
				    	</div>
    				))}
			    </div>
	    		))}
	    </PageContent>
	    <PageFooter color={'sand'}/>
    </div>
  );
}

export default connect()(Frontpage);