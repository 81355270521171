import React from 'react';
import './index.scss';
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'

import logo from './../../../assets/icons/logo.png'

function PageFooter(props) {
  return (
    props.footerData !== null ? 
    <div id="footer" className={`row color-bg-${props.color}`}>
    	<div className="row-inner">
    		<div className="col logo col4 col">
	  			<a href="https://www.gentofte.dk"><img className="logo" src={logo}/></a>
    		</div>
    		<div className="col links col4 col">
    			<h4>Genveje</h4>
    			<ul>
                {props.footerData.genveje.map((ele, i) => (
        				<li key={i}>
        					<a href={ele.url}>{ele.name}</a>
        				</li>
                    ))}
    			</ul>
			</div>
    		<div className="col contact col8">
    			<h4>Kontakt</h4>
    			<div dangerouslySetInnerHTML={{__html: props.footerData.kontakt}}/>
			</div>
    	</div>
    </div>
    : null
  );
}

export default connect((state) => ({
    footerData: state.App.appData
}))(PageFooter);