export function getApiBase() {
	return process.env.NODE_ENV === 'production' ? 'https://udviklingssamtalergentofte.dk/api' : 
	// 'http://dev.gentofte';
	// 'http://gentofte.troelsknud.dk/api';
	'https://udviklingssamtalergentofte.dk/api';
}

export function getURLBase() {
	return process.env.NODE_ENV === 'production' ? 'https://udviklingssamtalergentofte.dk' : 
	'http://localhost:3000';
	// 'http://gentofte.troelsknud.dk';
}

export function formatString(str) {
	var theString = str.replace('<p>','').replace('</p>','');
	return theString;
}